<template>
  <div class="admin-panel-links-parent">
    <div class="has-scrollbars">

      <div class="d-flex p-2 pb-0">
        <small class="text-muted mr-3">200 tasks | 20 reads | 100 writes</small>
        <span class=" text-muted mr-2 mb-0 text-white"><i class="fa fa-filter"></i> Filter / canceled</span>
        <span class=" text-muted mr-2 mb-0 text-white"><i class="fa fa-refresh"></i> Restart</span>
        <span class=" text-muted mr-2 mb-0 text-white"><i class="fa fa-wrench"></i> Programs</span>
        <span class=" text-muted mr-2 mb-0 text-white"><i class="fa fa-list"></i> List View</span>

        <span class=" text-muted ml-auto mb-0 text-white"><i class="fa fa-search"></i> Search</span>

        <!--
          <input
          type="text"
          placeholder="Search"
        /> -->
      </div>

      <table class="tabler">
        <thead>
          <tr class="task is-thead">
            <!-- <th>Type</th> -->
            <th>Group</th>
            <th>Action</th>
            <th>Value</th>
            <th>Status</th>
            <th>User</th>
            <!-- <td class="base"></td> -->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="i in 40"
            :key="i"
            class="task"
          >
            <!-- <td class="text-center"><i class="fa fa-music"></i></td> -->
            <td><i class="fa fa-angle-right mr-1"></i> <i class="fa fa-music mr-1"></i> youTubePlayer</td>
            <td>Lock</td>
            <td class="text-muted">None</td>
            <td>Loading</td>
            <td>System</td>
            <td class="base"></td>
          </tr>

        </tbody>
      </table>

    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
  };
</script>

<style lang="scss" scoped>
  .admin-panel-links-parent {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    pointer-events: auto;
    // border: 1px dashed yellow;
    border-bottom: 1px solid rgba(white, 0.1);
    background-color: #18181b;
  }

  .has-scrollbars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // padding: rem-calc(0 10 10 10);
    // padding-top: rem-calc(10);
  }

  .has-scrollbars a {
    display: block;
  }

  .admin-panel-links-parent,
  .admin-panel-links-parent * {
    pointer-events: auto;
  }

  textarea::placeholder {
    color: #0df293;
  }

  table tbody tr:nth-child(even) .base {
    background: rgba(white, 0.03);
  }

  thead {
    position: sticky;
    top: 0;
    left: 0;
    background: #18181b;
    // background: purple;
    // background: darken(#212126, 2%);
    // border-bottom: 1px solid red; //rgba(white, 0.1);
    box-shadow: 0 1px 1px 0 rgba(white, 0.1);
    border-top: 1px solid rgba(white, 0.1);
    height: rem-calc(30);
    z-index: 10;

    th {
      border-right: 1px solid rgba(white, 0.1);
    }
  }

  .task {
    position: relative;
    // display: flex;
    // min-width: 100%;
    // margin-bottom: rem-calc(5);
    // border: 1px solid rgba(white, 0.1);
    // padding: rem-calc(3 10);
    z-index: 1;

    td,
    th {
      padding: rem-calc(4 15);
      // color: #eee;
      color: #d4d4d5;
      font-size: rem-calc(13);
      font-weight: 600;
    }

    th {
      // color: #6633cc;
      // color: #405b87;
      // text-transform: uppercase;
    }

    .base {
      position: absolute;
      left: 5px;
      top: 0;
      width: calc(100% - 10px);
      height: 100%;
      // background: rgba(black, 0.3);
      // background: rgba(white, 0.03);
      // background: #212126;
      border-radius: 5px;
      z-index: -1;
    }
  }

  .tabler {
    // border-collapse: separate;
    // border-spacing: 0 3px;
    // table-layout: fixed;
    width: 100%;
  }
</style>
