import { render, staticRenderFns } from "./AdminPanelActiveTasks--draft.vue?vue&type=template&id=5d0865b0&scoped=true&"
import script from "./AdminPanelActiveTasks--draft.vue?vue&type=script&lang=js&"
export * from "./AdminPanelActiveTasks--draft.vue?vue&type=script&lang=js&"
import style0 from "./AdminPanelActiveTasks--draft.vue?vue&type=style&index=0&id=5d0865b0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d0865b0",
  null
  
)

export default component.exports